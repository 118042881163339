var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        title: "批量添加",
        width: "1100px",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "class" } },
                [
                  _c("class-select", {
                    ref: "classSelect",
                    on: { "class-select-change": _vm.setClassSelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "class" } },
                [
                  _c("brand-select", {
                    ref: "brandSelect",
                    on: { "brand-select-change": _vm.setBrand },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("goods-search", {
                    ref: "goodsSearch",
                    attrs: { search: false },
                    on: { "choose-goods-keyword": _vm.setKeyword },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                stripe: "",
                data: _vm.list,
                "row-key": "id",
                height: "370px",
              },
              on: { "selection-change": _vm.handleSelectRow },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              "popper-class": "custom-table-checkbox",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkList,
                                  callback: function ($$v) {
                                    _vm.checkList = $$v
                                  },
                                  expression: "checkList",
                                },
                              },
                              _vm._l(_vm.columns, function (item, index) {
                                return _c("el-checkbox", {
                                  key: index,
                                  attrs: { label: item.label },
                                })
                              }),
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [
                                _c("vab-remix-icon", {
                                  attrs: { icon: "settings-line" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.id && row.id) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  type: "selection",
                  width: "50",
                  "reserve-selection": true,
                },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item.label,
                    width: "",
                    prop: item.prop,
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.prop == "price"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  model: {
                                    value: row.price,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price", $$v)
                                    },
                                    expression: "row.price",
                                  },
                                })
                              : item.prop == "price_min"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  model: {
                                    value: row.price_min,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_min", $$v)
                                    },
                                    expression: "row.price_min",
                                  },
                                })
                              : item.prop == "price_max"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  model: {
                                    value: row.price_max,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_max", $$v)
                                    },
                                    expression: "row.price_max",
                                  },
                                })
                              : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.form.pageNo,
              layout: _vm.layout,
              "page-size": _vm.form.pageSize,
              total: _vm.total,
              background: "",
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
            [_vm._v("添加")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }