var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "多口味方案条码",
        visible: _vm.showDialog,
        width: "700px",
        top: "16vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "120px",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "商品:", prop: "" } }, [
                _vm._v(
                  " " + _vm._s(_vm.form.info && _vm.form.info.goods_name) + " "
                ),
              ]),
              _c("el-form-item", { attrs: { label: "规格:", prop: "" } }, [
                _vm._v(
                  " " + _vm._s(_vm.form.info && _vm.form.info.specs) + " "
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.form.rows } },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          item.prop == "x"
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: row.unit[0].price_code,
                                      callback: function ($$v) {
                                        _vm.$set(row.unit[0], "price_code", $$v)
                                      },
                                      expression: "row.unit[0].price_code",
                                    },
                                  }),
                                ],
                                1
                              )
                            : item.prop == "z"
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: row.unit[1].price_code,
                                      callback: function ($$v) {
                                        _vm.$set(row.unit[1], "price_code", $$v)
                                      },
                                      expression: "row.unit[1].price_code",
                                    },
                                  }),
                                ],
                                1
                              )
                            : item.prop == "d"
                            ? _c(
                                "div",
                                [
                                  _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: row.unit[2].price_code,
                                      callback: function ($$v) {
                                        _vm.$set(row.unit[2], "price_code", $$v)
                                      },
                                      expression: "row.unit[2].price_code",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("el-input", {
                                    model: {
                                      value: row[item.prop],
                                      callback: function ($$v) {
                                        _vm.$set(row, item.prop, $$v)
                                      },
                                      expression: "row[item.prop]",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-bottom" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }