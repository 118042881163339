var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        title: "关联商品",
        top: "5vh",
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "class" } },
                [
                  _c("class-select", {
                    ref: "classSelect",
                    on: { "class-select-change": _vm.setClassSelect },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "class" } },
                [
                  _c("brand-select", {
                    ref: "brandSelect",
                    on: { "brand-select-change": _vm.setBrand },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("goods-search", {
                    ref: "goodsSearch",
                    attrs: { search: false },
                    on: { "choose-goods-keyword": _vm.setKeyword },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v(" 查询已添加商品 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "margin-left": "100px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleBatchAdd },
                    },
                    [_vm._v("添加商品")]
                  ),
                ],
                1
              ),
              _vm.showApp
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          on: { change: _vm.setSwitch },
                          model: {
                            value: _vm.kaiguan,
                            callback: function ($$v) {
                              _vm.kaiguan = $$v
                            },
                            expression: "kaiguan",
                          },
                        },
                        [_vm._v(" 手机APP下单时仅可选择方案商品 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: { stripe: "", data: _vm.list, "row-key": "id" },
              on: { "selection-change": _vm.handleSelectRow },
            },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", type: "index", width: "50" } },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "custom-table-checkbox",
                            trigger: "hover",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.checkList,
                                callback: function ($$v) {
                                  _vm.checkList = $$v
                                },
                                expression: "checkList",
                              },
                            },
                            _vm._l(_vm.columns, function (item, index) {
                              return _c("el-checkbox", {
                                key: index,
                                attrs: { label: item.label },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              slot: "reference",
                            },
                            [
                              _c("vab-remix-icon", {
                                attrs: { icon: "settings-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  "reserve-selection": "",
                  align: "center",
                  type: "selection",
                  width: "50",
                },
              }),
              _vm._l(_vm.finallyColumns, function (item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: item.label,
                    width: "",
                    prop: item.prop,
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label == "商品名称"
                              ? _c("div", [
                                  row.has_sub
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "underline",
                                          staticStyle: { color: "red" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goodsDetail(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(row[item.prop]) +
                                              "(多口味) "
                                          ),
                                        ]
                                      )
                                    : _c("div", [
                                        _vm._v(_vm._s(row[item.prop])),
                                      ]),
                                ])
                              : item.prop == "price"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        row,
                                        "price",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.price,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price", $$v)
                                    },
                                    expression: "row.price",
                                  },
                                })
                              : item.prop == "price_min"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        row,
                                        "price_min",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.price_min,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_min", $$v)
                                    },
                                    expression: "row.price_min",
                                  },
                                })
                              : item.prop == "price_max"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        row,
                                        "price_max",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.price_max,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_max", $$v)
                                    },
                                    expression: "row.price_max",
                                  },
                                })
                              : item.prop == "price_return"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        row,
                                        "price_return",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.price_return,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_return", $$v)
                                    },
                                    expression: "row.price_return",
                                  },
                                })
                              : item.label == "退库价"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        row,
                                        "price_return",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.price_return,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_return", $$v)
                                    },
                                    expression: "row.price_return",
                                  },
                                })
                              : item.label == "条码"
                              ? _c("el-input", {
                                  staticClass: "input-center",
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleChange(
                                        row,
                                        "price_code",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: row.price_code,
                                    callback: function ($$v) {
                                      _vm.$set(row, "price_code", $$v)
                                    },
                                    expression: "row.price_code",
                                  },
                                })
                              : _c("span", [_vm._v(_vm._s(row[item.prop]))]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm.defaultApi == 1
                ? _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "成本价毛利率",
                        prop: "rate",
                        width: "130px",
                        align: "center",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "400",
                                trigger: "click",
                                content:
                                  "成本价毛利率 =（调拨价 - 成本价）/ 成本价×100%",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(" 成本价毛利率 "),
                                  _c("i", {
                                    staticClass: "el-icon-warning",
                                    staticStyle: {
                                      "font-size": "16px",
                                      color: "#f60",
                                      cursor: "pointer",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  align: "center",
                  label: "操作",
                  fixed: "right",
                  "min-width": "100px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDeleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "bottom-units" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { model: _vm.bottomForm, inline: "" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        { on: { click: _vm.handleBatchDeleteRow } },
                        [_vm._v("批量删除")]
                      ),
                    ],
                    1
                  ),
                  _vm.defaultApi == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "130px" },
                              model: {
                                value: _vm.bottomForm.field,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bottomForm, "field", $$v)
                                },
                                expression: "bottomForm.field",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "调拨价浮动", value: "price" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "最低价浮动",
                                  value: "price_min",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "最高价浮动",
                                  value: "price_max",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "退库价浮动",
                                  value: "price_return",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "130px" },
                              model: {
                                value: _vm.bottomForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.bottomForm, "type", $$v)
                                },
                                expression: "bottomForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "方案价上浮", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "方案价下调", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "80px" },
                        model: {
                          value: _vm.bottomForm.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.bottomForm, "value", $$v)
                          },
                          expression: "bottomForm.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px" },
                          model: {
                            value: _vm.bottomForm.type_unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.bottomForm, "type_unit", $$v)
                            },
                            expression: "bottomForm.type_unit",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "%", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "元", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleBatchAdj("1")
                            },
                          },
                        },
                        [_vm._v("调整选择")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleBatchAdj("2")
                            },
                          },
                        },
                        [_vm._v("批量调整")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pageNo,
                  layout: _vm.layout,
                  "page-size": _vm.pageSize,
                  total: _vm.totalCount,
                  background: "",
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleImport } }, [
            _vm._v("导入"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.handleExport } },
            [_vm._v("导出")]
          ),
          _c("el-button", { on: { click: _vm.handleImportMore } }, [
            _vm._v("导入多口味"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.handleExportMore } },
            [_vm._v("导出多口味")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
      _c("batch-add", { ref: "batchAdd", on: { refresh: _vm.fetchData2 } }),
      _c("import-tpl", { ref: "importTpl" }),
      _c("GoodsDetail", { ref: "goodsDetail", on: { refresh: _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }