<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    title="批量添加"
    width="1100px"
    top="5vh"
  >
    <div class="wrapper">
      <el-form ref="form" :model="form" inline>
        <el-form-item prop="class">
          <class-select
            ref="classSelect"
            @class-select-change="setClassSelect"
          ></class-select>
        </el-form-item>

        <el-form-item prop="class">
          <brand-select
            ref="brandSelect"
            @brand-select-change="setBrand"
          ></brand-select>
        </el-form-item>

        <el-form-item>
          <goods-search
            ref="goodsSearch"
            :search="false"
            @choose-goods-keyword="setKeyword"
          ></goods-search>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        v-loading="loading"
        stripe
        :data="list"
        row-key="id"
        height="370px"
        @selection-change="handleSelectRow"
      >
        <!-- 序号 -->
        <el-table-column align="center" width="50">
          <template #header>
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
          <template #default="{ row }">
            {{ row.id && row.id }}
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :label="item.label"
          width=""
          :prop="item.prop"
          align="center"
        >
          <template #default="{ row }">
            <el-input
              v-if="item.prop == 'price'"
              v-model="row.price"
              class="input-center"
            ></el-input>

            <el-input
              v-else-if="item.prop == 'price_min'"
              v-model="row.price_min"
              class="input-center"
            ></el-input>

            <el-input
              v-else-if="item.prop == 'price_max'"
              v-model="row.price_max"
              class="input-center"
            ></el-input>

            <span v-else>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="form.pageNo"
        :layout="layout"
        :page-size="form.pageSize"
        :total="total"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleAdd">添加</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import {
    getBatchLinkGoodsList,
    getBatchLinkGoodsListT,
    batchAddGoods,
    batchAddGoodsT,
  } from '@/api/setPrice'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  export default {
    name: 'BatchAdd',
    components: {
      GoodsSearch,
      ClassSelect,
      BrandSelect,
    },
    data() {
      return {
        id: '',
        showDialog: false,
        loading: true,
        // 是否是调拨 默认false
        isTrans: false,
        form: {
          pageNo: 1,
          pageSize: 50,
          class_id: '',
          brand_id: '',
          keyword: '',
        },
        bottomForm: {},
        selectList: {
          class: [],
          brand: [],
        },

        total: 0,
        layout: 'sizes, prev, pager, next, jumper',
        // layout: 'total, prev, pager, next',
        list: [],
        checkList: [
          '商品名称',
          '规格',
          '单位',
          '基础售价',
          '方案价',
          '最低价',
          '最高价',
        ],
        columns: [
          {
            order: 1,
            label: '商品名称',
            prop: 'goods_name',
            width: '160px',
          },
          {
            order: 2,
            label: '规格',
            prop: 'specs',
            width: '160px',
          },
          {
            order: 3,
            label: '单位',
            prop: 'goods_unit',
            width: '90px',
          },
          {
            order: 4,
            label: '基础售价',
            prop: 'sell_price',
            width: '110px',
          },
          {
            order: 5,
            label: '方案价',
            prop: 'price',
            width: '100px',
          },
          {
            order: 6,
            label: '最低价',
            prop: 'price_min',
            width: '100px',
          },
          {
            order: 7,
            label: '最高价',
            prop: 'price_max',
            width: '100px',
          },
        ],
        // 选中的row
        selectRow: [],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        console.log(arr)
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          // this.id = ''
          console.log('close')
          this.resetForm()
          this.loading = true
          this.isTrans = false
          // this.selectRow = []
          this.$refs.table.clearSelection()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 获取表格数据
      async fetchData() {
        if (this.isTrans) {
          this.loading = true
          let { data, code, msg, totalCount } = await getBatchLinkGoodsListT(
            Object.assign(this.form, { id: this.id })
          )
          console.log(data)
          this.list = data
          this.total = totalCount
          this.loading = false
        } else {
          this.loading = true
          let { data, code, msg, totalCount } = await getBatchLinkGoodsList(
            Object.assign(this.form, { id: this.id })
          )
          console.log(data)
          this.list = data
          this.total = totalCount
          this.loading = false
        }
      },
      resetForm() {
        this.$refs.classSelect.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.goodsSearch.resetForm()
        this.form.brand_id = ''
      },
      setKeyword(val) {
        this.form.keyword = val
      },
      handleQuery() {
        console.log(this.form)
        this.fetchData()
      },
      close() {
        this.showDialog = false
      },
      setClassSelect(val) {
        this.form.class_id = val
      },
      setBrand(val) {
        //测试
        this.form.brand_id = val
      },
      // 选择表格row触发
      handleSelectRow(arr, row) {
        console.log(arr)
        console.log(row)
        this.selectRow = arr
      },
      handleAdd() {
        console.log(this.selectRow)
        if (this.selectRow.length == 0) {
          this.$message.error('请勾选商品')
          return false
        }
        // this.$emit('batch-add-row', this.selectRow)
        if (this.isTrans) {
          batchAddGoodsT({
            id: this.id,
            goods: JSON.stringify(this.selectRow),
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              // this.fetchData()
              this.$emit('refresh')
            }
          })
        } else {
          batchAddGoods({
            id: this.id,
            goods: JSON.stringify(this.selectRow),
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success('添加成功')
              // this.fetchData()
              this.$emit('refresh')
            }
          })
        }

        this.showDialog = false
      },
      // 分页
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .bottom-units {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
</style>
