<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    title="关联商品"
    top="5vh"
    fullscreen
  >
    <div class="wrapper">
      <el-form :model="form" inline>
        <el-form-item prop="class">
          <class-select
            ref="classSelect"
            @class-select-change="setClassSelect"
          ></class-select>
        </el-form-item>

        <el-form-item prop="class">
          <brand-select
            ref="brandSelect"
            @brand-select-change="setBrand"
          ></brand-select>
        </el-form-item>

        <el-form-item>
          <goods-search
            ref="goodsSearch"
            :search="false"
            @choose-goods-keyword="setKeyword"
          ></goods-search>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleQuery">
            查询已添加商品
          </el-button>
        </el-form-item>
        <el-form-item style="margin-left: 100px">
          <el-button type="primary" @click="handleBatchAdd">添加商品</el-button>
        </el-form-item>
        <el-form-item v-if="showApp">
          <el-checkbox
            v-model="kaiguan"
            :true-label="1"
            :false-label="0"
            @change="setSwitch"
          >
            手机APP下单时仅可选择方案商品
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        v-loading="loading"
        stripe
        :data="list"
        row-key="id"
        @selection-change="handleSelectRow"
      >
        <!-- 序号 -->
        <el-table-column align="center" type="index" width="50">
          <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in columns"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          reserve-selection
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          :label="item.label"
          width=""
          :prop="item.prop"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '商品名称'">
              <div
                v-if="row.has_sub"
                style="color: red"
                class="underline"
                @click="goodsDetail(row)"
              >
                {{ row[item.prop] }}(多口味)
              </div>
              <div v-else>{{ row[item.prop] }}</div>
            </div>
            <el-input
              v-else-if="item.prop == 'price'"
              v-model="row.price"
              class="input-center"
              @change="handleChange(row, 'price', $event)"
            ></el-input>

            <el-input
              v-else-if="item.prop == 'price_min'"
              v-model="row.price_min"
              class="input-center"
              @change="handleChange(row, 'price_min', $event)"
            ></el-input>

            <el-input
              v-else-if="item.prop == 'price_max'"
              v-model="row.price_max"
              class="input-center"
              @change="handleChange(row, 'price_max', $event)"
            ></el-input>
            <el-input
              v-else-if="item.prop == 'price_return'"
              v-model="row.price_return"
              class="input-center"
              @change="handleChange(row, 'price_return', $event)"
            ></el-input>
            <el-input
              v-else-if="item.label == '退库价'"
              v-model="row.price_return"
              class="input-center"
              @change="handleChange(row, 'price_return', $event)"
            ></el-input>

            <el-input
              v-else-if="item.label == '条码'"
              v-model="row.price_code"
              class="input-center"
              @change="handleChange(row, 'price_code', $event)"
            ></el-input>
            <span v-else>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="defaultApi == 1"
          label="成本价毛利率"
          prop="rate"
          width="130px"
          align="center"
        >
          <template slot="header">
            <!-- 成本价毛利率
            <i
              class="el-icon-warning"
              style="font-size: 16px; color: #f60; cursor: pointer"
            ></i> -->
            <el-popover
              placement="top"
              width="400"
              trigger="click"
              content="成本价毛利率 =（调拨价 - 成本价）/ 成本价×100%"
            >
              <span slot="reference">
                成本价毛利率
                <i
                  class="el-icon-warning"
                  style="font-size: 16px; color: #f60; cursor: pointer"
                ></i>
              </span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          align="center"
          label="操作"
          fixed="right"
          min-width="100px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="bottom-units">
        <el-form :model="bottomForm" inline style="margin-top: 20px">
          <el-form-item>
            <el-button @click="handleBatchDeleteRow">批量删除</el-button>
          </el-form-item>

          <el-form-item v-if="defaultApi == 1" label="" prop="">
            <el-select v-model="bottomForm.field" style="width: 130px">
              <!-- ('price', 'price_min', 'price_max','price_return') -->
              <el-option label="调拨价浮动" value="price"></el-option>
              <el-option label="最低价浮动" value="price_min"></el-option>
              <el-option label="最高价浮动" value="price_max"></el-option>
              <el-option label="退库价浮动" value="price_return"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-else>
            <el-select v-model="bottomForm.type" style="width: 130px">
              <el-option label="方案价上浮" value="1"></el-option>
              <el-option label="方案价下调" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="bottomForm.value" style="width: 80px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="bottomForm.type_unit" style="width: 80px">
              <el-option label="%" value="1"></el-option>
              <el-option label="元" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleBatchAdj('1')">调整选择</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleBatchAdj('2')">批量调整</el-button>
          </el-form-item>
        </el-form>
        <el-pagination
          :current-page="pageNo"
          :layout="layout"
          :page-size="pageSize"
          :total="totalCount"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <!-- <el-button type="primary">保存</el-button> -->
      <el-button @click="handleImport">导入</el-button>
      <el-button type="warning" @click="handleExport">导出</el-button>

      <el-button @click="handleImportMore">导入多口味</el-button>
      <el-button type="warning" @click="handleExportMore">导出多口味</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <batch-add ref="batchAdd" @refresh="fetchData2"></batch-add>
    <import-tpl ref="importTpl"></import-tpl>
    <GoodsDetail ref="goodsDetail" @refresh="fetchData"></GoodsDetail>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import importTpl from '@/views/index/components/importTpl.vue'
  import GoodsDetail from './goodsDetail.vue'
  import {
    getLinkGoodsList,
    setLinkGoods,
    setLinkGoodsT,
    deleteBrandRow,
    linkGoodsSetPrice,
    linkGoodsSetPriceT,
    getLinkGoodsListT,
    deleteBrandRowT,
  } from '@/api/setPrice'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClassSelect from '@/baseComponents/classSelect'
  import BrandSelect from '@/baseComponents/brandSelect'
  import BatchAdd from './batchAdd'
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'LinkGoods',
    components: {
      GoodsSearch,
      ClassSelect,
      BrandSelect,
      BatchAdd,
      importTpl,
      GoodsDetail,
    },
    props: {
      diaobojia: {
        type: Boolean,
        default: false,
      },
      showApp: {
        type: Boolean,
        default: false,
      },
      importType: { type: Number, default: 12 },
      importMoveType: { type: Number, default: 14 },
    },
    data() {
      return {
        defaultApi: null,
        id: '',
        showDialog: false,
        loading: true,
        form: {
          class_id: '',
          brand_id: '',
          keyword: '',
        },
        bottomForm: {
          value: '',
          type: '1',
          type_unit: '1',
          field: '',
        },
        selectList: {
          class: [],
          brand: [],
        },
        selectRow: [],
        pageNo: 1,
        kaiguan: 0,

        pageSize: 10,
        totalCount: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        list: [],
        // checkList: [
        //   '商品名称',
        //   '规格',
        //   '单位',
        //   '基础售价',
        //   '方案价',
        //   '最低价',
        //   '最高价',
        //   '退货价',
        //   '条码',
        // ],
        // columns: [
        //   {
        //     order: 1,
        //     label: '商品名称',
        //     prop: 'goods_name',
        //     width: 'auto',
        //   },
        //   {
        //     order: 2,
        //     label: '规格',
        //     prop: 'specs',
        //     width: '160px',
        //   },
        //   {
        //     order: 3,
        //     label: '单位',
        //     prop: 'goods_unit',
        //     width: '70px',
        //   },
        //   {
        //     order: 4,
        //     label: '基础售价',
        //     prop: 'sell_price',
        //     width: '110px',
        //   },
        //   {
        //     order: 5,
        //     label: '方案价',
        //     prop: 'price',
        //     width: '90px',
        //   },
        //   {
        //     order: 6,
        //     label: '最低价',
        //     prop: 'price_min',
        //     width: '90px',
        //   },
        //   {
        //     order: 7,
        //     label: '最高价',
        //     prop: 'price_max',
        //     width: '90px',
        //   },
        //   {
        //     order: 8,
        //     label: '退货价',
        //     prop: 'price_return',
        //     width: '90px',
        //   },
        //   {
        //     order: 9,
        //     label: '条码',
        //     prop: 'price_code',
        //     width: '110px',
        //   },
        // ],
      }
    },
    computed: {
      checkList() {
        if (this.diaobojia) {
          return [
            '商品名称',
            '规格',
            '单位',
            '基础售价',
            '方案价',
            '最低价',
            '最高价',
            '条码',
          ]
        } else {
          return [
            '商品名称',
            '规格',
            '单位',
            '基础售价',
            '方案价',
            '最低价',
            '最高价',
            '退货价',
            '条码',
          ]
        }
      },
      columns() {
        if (this.diaobojia) {
          return [
            {
              order: 1,
              label: '商品名称',
              prop: 'goods_name',
              width: 'auto',
            },
            {
              order: 2,
              label: '规格',
              prop: 'specs',
              width: '160px',
            },
            {
              order: 3,
              label: '单位',
              prop: 'goods_unit',
              width: '70px',
            },
            {
              order: 4,
              label: '基础售价',
              prop: 'sell_price',
              width: '110px',
            },
            {
              order: 5,
              label: '方案价',
              prop: 'price',
              width: '90px',
            },
            {
              order: 6,
              label: '最低价',
              prop: 'price_min',
              width: '90px',
            },
            {
              order: 7,
              label: '最高价',
              prop: 'price_max',
              width: '90px',
            },
            {
              order: 9,
              label: '条码',
              prop: 'price_code',
              width: '110px',
            },
          ]
        } else {
          return [
            {
              order: 1,
              label: '商品名称',
              prop: 'goods_name',
              width: 'auto',
            },
            {
              order: 2,
              label: '规格',
              prop: 'specs',
              width: '160px',
            },
            {
              order: 3,
              label: '单位',
              prop: 'goods_unit',
              width: '70px',
            },
            {
              order: 4,
              label: '基础售价',
              prop: 'sell_price',
              width: '110px',
            },
            {
              order: 5,
              label: '方案价',
              prop: 'price',
              width: '90px',
            },
            {
              order: 6,
              label: '最低价',
              prop: 'price_min',
              width: '90px',
            },
            {
              order: 7,
              label: '最高价',
              prop: 'price_max',
              width: '90px',
            },
            {
              order: 8,
              label: '退货价',
              prop: 'price_return',
              width: '90px',
            },
            {
              order: 9,
              label: '条码',
              prop: 'price_code',
              width: '110px',
            },
          ]
        }
      },
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        console.log(arr)
        let a = _.sortBy(arr, (item) => item.order)
        console.log('a', a)
        if (this.defaultApi == 1) {
          a = a.concat([
            {
              order: 8,
              label: '退库价',
              prop: 'price_return',
              width: '100px',
            },
          ])
          return a
        } else {
          // TODO 等接口 设置价格方案和调拨价方案添加表格字段
          //a = a.concat([
          //   {
          //     order: 8,
          //     label: '基础退货价',
          //     prop: 'price_return',
          //     width: '100px',
          //   },
          //   {
          //     order: 9,
          //     label: '方案退货价',
          //     prop: 'rate',
          //     width: '120px',
          //   },
          // ])
          return a
        }
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.pageNo = 1
          this.pageSize = 10
          this.totalCount = 0
          this.id = ''
          this.resetForm()
          this.loading = true
          this.defaultApi = null
          this.selectRow = []
          this.$refs.table.clearSelection()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      // 多口味详情
      goodsDetail(row) {
        console.log('r', row)
        this.$refs.goodsDetail.row = row
        this.$refs.goodsDetail.showDialog = true
      },
      // 获取表格数据
      async fetchData() {
        if (this.showApp) {
          let kaiguan = await postAction('/saleAdmin/scheme/view', {
            id: this.id,
          })
          this.kaiguan = kaiguan.data.flag_goods
        }
        if (this.defaultApi) {
          let { data, code, msg, totalCount } = await getLinkGoodsListT(
            Object.assign(this.form, {
              id: this.id,
              pageNo: this.pageNo,
              pageSize: this.pageSize,
            })
          )
          console.log(data)
          this.list = data
          this.totalCount = totalCount
          this.loading = false
        } else {
          let { data, code, msg, totalCount } = await getLinkGoodsList(
            Object.assign(this.form, {
              id: this.id,
              pageNo: this.pageNo,
              pageSize: this.pageSize,
            })
          )
          console.log(data)
          this.list = data
          this.totalCount = totalCount
          this.loading = false
        }
      },
      fetchData2() {
        this.pageNo = 1
        this.pageSize = 10
        this.fetchData()
      },
      handleChange(row, field, val) {
        console.log('handleChange')
        console.log(row, val)
        let data = {
          scheme_id: this.id,
          id: row.id,
          field,
          value: val,
        }
        console.log(data)
        // 调用保存接口
        if (this.defaultApi == 1) {
          setLinkGoodsT(data)
        } else {
          setLinkGoods(data)
        }
        // console.log(res)
        // clearTimeout(this.timer)
        // this.timer = setTimeout(() => {
        //   console.log('刷新')
        //   this.fetchData()
        // }, 1000)
      },
      setKeyword(val) {
        this.form.keyword = val
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleBatchAdd() {
        console.log(this.form)
        if (this.defaultApi) {
          this.$refs['batchAdd'].isTrans = true
        }
        this.$refs['batchAdd'].showDialog = true
        this.$refs['batchAdd'].id = this.id
      },
      close() {
        this.showDialog = false
      },
      setClassSelect(val) {
        this.form.class_id = val
      },
      setBrand(val) {
        this.form.brand_id = val
      },
      // 分页
      handleCurrentChange(val) {
        this.loading = true
        this.pageNo = val
        this.fetchData()
        this.loading = false
      },
      handleSizeChange(val) {
        this.loading = true

        this.pageSize = val
        this.fetchData()
        this.loading = false
      },
      resetForm() {
        this.$refs.classSelect.resetForm()
        this.$refs.brandSelect.resetForm()
        this.$refs.goodsSearch.resetForm()
      },
      handleSelectRow(val) {
        this.selectRow = val
      },
      // 设置开关
      async setSwitch(val) {
        let data = await postAction('/saleAdmin/scheme/flag-set', {
          scheme_id: this.id,
          flag_goods: val,
        })
        this.$message.success(data.msg)
      },
      // 批量删除
      handleBatchDeleteRow() {
        let arr = []
        this.selectRow.forEach((item) => {
          arr.push(item.id)
        })
        let ids = arr.join(',')

        this.$confirm('确定删除选择项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            if (this.defaultApi == 1) {
              deleteBrandRowT({
                scheme_id: this.id,
                ids,
              }).then((res) => {
                this.$message.success('删除成功')
                this.selectRow = []
                this.$refs.table.clearSelection()
                this.pageNo = 1
                this.pageSize = 10
                this.fetchData()
              })
            } else {
              deleteBrandRow({
                scheme_id: this.id,
                ids,
              }).then((res) => {
                this.$message.success('删除成功')
                this.selectRow = []
                this.$refs.table.clearSelection()

                this.fetchData()
              })
            }
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleDeleteRow(index, row) {
        // this.list.splice(index, 1)
        // console.log(this.list)
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            if (this.defaultApi == 1) {
              deleteBrandRowT({
                scheme_id: this.id,
                ids: row.id,
              }).then((res) => {
                if (res.code == 200) {
                  this.$message.success('删除成功')
                  this.fetchData()
                }
              })
            } else {
              deleteBrandRow({
                scheme_id: this.id,
                ids: row.id,
              }).then((res) => {
                if (res.code == 200) {
                  this.$message.success('删除成功')
                  this.fetchData()
                }
              })
            }
          })
          .catch(() => {
            console.log('no')
          })
      },
      // 底部功能按钮
      // 批量调整
      handleBatchAdj(all = '1') {
        if (this.bottomForm.value !== '') {
          let selectRow = this.selectRow
          let selecteId = []
          selectRow.forEach((item) => {
            selecteId.push(item.id)
          })
          let ids = selecteId.join(',')
          if (all == '1') {
            console.log('xuanze')
            if (ids == '') {
              this.$message.error('请勾选数据')
              return false
            }
            // 调整选择
            var endData = Object.assign(this.bottomForm, {
              scheme_id: this.id,
              ids,
            })
          } else if (all == '2') {
            console.log('all')
            // 调整全部
            var endData = Object.assign(this.bottomForm, {
              scheme_id: this.id,
              ids: '',
            })
          }

          if (this.defaultApi == 1) {
            // 调拨方案加api
            linkGoodsSetPriceT(endData).then((res) => {
              this.$message.success('调整成功')
              this.fetchData()
            })
          } else {
            linkGoodsSetPrice(endData).then((res) => {
              this.$message.success('调整成功')
              this.fetchData()
            })
          }
        } else {
          this.$message.error('请输入需要调整的百分比或价格')
        }
      },
      handleExport() {
        downloadFile(
          '/saleAdmin/scheme/goods-list-export2',
          '关联商品.xlsx',
          this.form
        )
      },
      handleImport() {
        // this.$refs.importTpl.data.type = 6
        this.$refs.importTpl.data.type = this.importType
        this.$refs.importTpl.data.data_id = this.id
        this.$refs.importTpl.title = '导入'
        this.$refs.importTpl.showDialog = true
      },

      handleImportMore() {
        this.$refs.importTpl.data.type = this.importMoveType
        this.$refs.importTpl.data.data_id = this.id
        this.$refs.importTpl.title = '导入多口味'
        this.$refs.importTpl.showDialog = true
      },
      handleExportMore() {
        downloadFile(
          '/saleAdmin/scheme/export-goods-sub',
          '多口味商品导出.xlsx',
          { scheme_id: this.id }
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .bottom-units {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
</style>
