<template>
  <el-dialog
    v-loading="loading"
    title="多口味方案条码"
    :visible.sync="showDialog"
    width="700px"
    top="16vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="商品:" prop="">
          {{ form.info && form.info.goods_name }}
        </el-form-item>
        <el-form-item label="规格:" prop="">
          {{ form.info && form.info.specs }}
        </el-form-item>
      </el-form>
      <el-table :data="form.rows">
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.prop == 'x'">
              <!-- {{ row.unit[0].price_code }} -->
              <el-input v-model="row.unit[0].price_code" type="text" />
            </div>
            <div v-else-if="item.prop == 'z'">
              <el-input v-model="row.unit[1].price_code" type="text" />
            </div>
            <div v-else-if="item.prop == 'd'">
              <el-input v-model="row.unit[2].price_code" type="text" />
            </div>
            <div v-else>
              <el-input v-model="row[item.prop]"></el-input>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-bottom">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="showDialog = false">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'GoodsDetail',
    components: {},
    data() {
      return {
        row: {},
        form: {},
        loading: false,
        list: [],
        showDialog: false,
        columns: [
          {
            order: 1,
            label: '口味',
            prop: 'goods_attr',
            width: '',
          },
          {
            order: 2,
            label: '小单位条码',
            prop: 'x',
            width: '',
          },
          {
            order: 3,
            label: '中单位条码',
            prop: 'z',
            width: '',
          },
          {
            order: 4,
            label: '大单位条码',
            prop: 'd',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        // 价格方案多口味弹窗  saleAdmin/scheme/goods-sub
        // 价格方案多口味弹窗提交  /saleAdmin/scheme/sub-set
        this.loading = true
        try {
          const { data } = await postAction(
            'saleAdmin/scheme/goods-sub',
            this.row
          )
          console.log('data', data)
          this.form = data
        } catch (err) {
          this.loading = false
        }
        this.loading = false
      },
      async handleSubmit() {
        this.loading = true
        try {
          let res = await postAction('/saleAdmin/scheme/sub-set', {
            ...{
              scheme_id: this.row.scheme_id,
              goods_id: this.row.goods_id,
            },
            ...{
              goods_data: JSON.stringify(this.form.rows),
            },
          })
          this.$emit('refresh')
          this.$message.success('操作成功')
          this.showDialog = false
        } catch (err) {
          this.loading = false
        }
        this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
